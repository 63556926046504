'use client';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { useState, type ReactNode, type MouseEvent, useMemo } from 'react';

import { ChevronRight } from '@shared/ui-components';

import { DEFAULT_LOCALE, Link } from '../../../../i18n';
import { useBreakpoint } from '../../../../utils/hooks/use-breakpoint';
import { type NavigationItem } from '../../../../utils/types/navigation-items';
import { useUserContext } from '../../../providers/user-context';
import { getCurrentPageSubItemIds } from '../../utils';
import { useVisibleElement } from '../../utils/hooks/use-visible-element';

import { SubMenu } from './sub-menu';
import styles from './navigation.module.scss';

type NavigationProps = {
  authenticationSection?: ReactNode;
  close?: () => void;
  dropdownItemsSlot?: ReactNode;
  isMobile?: boolean;
  isSecondaryTheme?: boolean;
  languageSwitcherSlot?: ReactNode;
  locale: string;
  navigationItems: NavigationItem[];
  profileSummarySlot?: ReactNode;
};

export const Navigation = ({
  authenticationSection,
  close,
  dropdownItemsSlot,
  isMobile = false,
  isSecondaryTheme,
  languageSwitcherSlot,
  locale,
  navigationItems,
  profileSummarySlot,
  ...props
}: NavigationProps) => {
  const pathname = usePathname();
  const { status } = useUserContext();
  const isAuthenticated = status === 'authenticated';
  const [expandedMenuItem, setExpandedMenuItem] = useState<string | undefined>(undefined);

  const subItemIds = useMemo(
    () => getCurrentPageSubItemIds(navigationItems, pathname),
    [navigationItems, pathname]
  );

  const { isDesktop } = useBreakpoint();
  const visibleSection = useVisibleElement({
    disabled: isMobile || !isDesktop,
    elementIds: subItemIds,
  });

  const handleClick = (event: MouseEvent<HTMLAnchorElement>, navigationItem: NavigationItem) => {
    if (isMobile && navigationItem.subMenu && navigationItem.subMenu.length > 0) {
      event.preventDefault();
      setExpandedMenuItem(
        expandedMenuItem === navigationItem.title ? undefined : navigationItem.title
      );
    } else {
      close?.();
    }
  };

  const updatedNavigationItems = navigationItems.map((navItem) => {
    const formattedUrl =
      locale === DEFAULT_LOCALE ? navItem.route : navItem.route.replace(/^\/[-\w]+\//u, '/');
    navItem.route = formattedUrl;
    return navItem;
  });

  return (
    <div className={styles['navigation-wrapper']}>
      {profileSummarySlot && isAuthenticated && profileSummarySlot}
      <nav
        className={classNames(styles.navigation, {
          [styles['navigation--secondary']]: isSecondaryTheme,
          [styles['navigation--not-authenticated']]: !isAuthenticated,
        })}
        {...props}
      >
        <ul className={styles['navigation__list']}>
          {updatedNavigationItems.map((navigationItem) => {
            const showDesktopSubMenu = navigationItem.subMenu && navigationItem.route === pathname;
            return (
              <li className={styles['navigation__list-item']} key={navigationItem.title}>
                <Link
                  aria-expanded={expandedMenuItem === navigationItem.title}
                  className={classNames(styles['navigation__list-item-link'], {
                    [styles['navigation__list-item-link--expanded']]:
                      expandedMenuItem === navigationItem.title,
                    [styles['navigation__list-item-link--active']]:
                      pathname === navigationItem.route,
                  })}
                  href={navigationItem.route}
                  onClick={(event) => handleClick(event, navigationItem)}
                >
                  {navigationItem.title}
                  {isMobile && navigationItem.subMenu && navigationItem.subMenu.length > 0 && (
                    <ChevronRight
                      className={classNames(styles['navigation__list-item-icon'], {
                        [styles['navigation__list-item-icon--expanded']]:
                          expandedMenuItem === navigationItem.title,
                      })}
                    />
                  )}
                </Link>
                {(isMobile || showDesktopSubMenu) && (
                  <SubMenu
                    activeSubItem={visibleSection}
                    isExpanded={expandedMenuItem === navigationItem.title}
                    navItem={navigationItem}
                    onClose={close}
                  />
                )}
              </li>
            );
          })}
        </ul>
        {(languageSwitcherSlot || dropdownItemsSlot) && (
          <ul className={classNames(styles['navigation__list-secondary'])}>
            {languageSwitcherSlot && <li>{languageSwitcherSlot}</li>}
            {isAuthenticated && dropdownItemsSlot}
          </ul>
        )}
      </nav>
      {!isAuthenticated && (
        <div className={styles['navigation__authentication']}>{authenticationSection}</div>
      )}
    </div>
  );
};
