'use client';

import { useCallback, useEffect, useState } from 'react';

import { useViewportHeight } from '@shared/utils';

import { useSubNavigationContext } from '../../../providers/sub-navigation-context';

const DEFAULT_FOCUS_MARGIN = 200;

const getObserverOptions = (viewPortHeight: number, focusMargin: number) => ({
  rootMargin: `-${focusMargin}px 0px -${viewPortHeight - focusMargin - 1}px 0px`,
});

export const useVisibleElement = ({
  elementIds,
  focusMargin = DEFAULT_FOCUS_MARGIN,
  disabled = false,
}: {
  disabled?: boolean;
  elementIds: string[];
  focusMargin?: number;
}) => {
  const viewPortHeight = useViewportHeight();
  const { elements: sections, registerLinks } = useSubNavigationContext();
  const [visibleElement, setVisibleElement] = useState<string | null>(null);

  useEffect(() => {
    if (!disabled) registerLinks(elementIds);
  }, [disabled, elementIds, registerLinks]);

  const onIntersect: IntersectionObserverCallback = useCallback((entries) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        continue;
      }

      setVisibleElement(entry.target.id);
    }
  }, []);

  useEffect(() => {
    if (!viewPortHeight || !sections || disabled) {
      return () => undefined;
    }

    const options = getObserverOptions(viewPortHeight, focusMargin);

    const observer = new IntersectionObserver(onIntersect, options);

    for (const section of sections) {
      observer.observe(section);
    }

    return () => observer.disconnect();
  }, [disabled, elementIds, focusMargin, onIntersect, sections, viewPortHeight]);

  return visibleElement;
};
